.margin-left-100 {
  margin-left: 100px;
}
.margin-right-100 {
  margin-right: 100px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-100 {
  margin: 100px;
}
.margin-left-95 {
  margin-left: 95px;
}
.margin-right-95 {
  margin-right: 95px;
}
.margin-top-95 {
  margin-top: 95px;
}
.margin-bottom-95 {
  margin-bottom: 95px;
}
.margin-95 {
  margin: 95px;
}
.margin-left-90 {
  margin-left: 90px;
}
.margin-right-90 {
  margin-right: 90px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-bottom-90 {
  margin-bottom: 90px;
}
.margin-90 {
  margin: 90px;
}
.margin-left-85 {
  margin-left: 85px;
}
.margin-right-85 {
  margin-right: 85px;
}
.margin-top-85 {
  margin-top: 85px;
}
.margin-bottom-85 {
  margin-bottom: 85px;
}
.margin-85 {
  margin: 85px;
}
.margin-left-80 {
  margin-left: 80px;
}
.margin-right-80 {
  margin-right: 80px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-80 {
  margin: 80px;
}
.margin-left-75 {
  margin-left: 75px;
}
.margin-right-75 {
  margin-right: 75px;
}
.margin-top-75 {
  margin-top: 75px;
}
.margin-bottom-75 {
  margin-bottom: 75px;
}
.margin-75 {
  margin: 75px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-70 {
  margin: 70px;
}
.margin-left-65 {
  margin-left: 65px;
}
.margin-right-65 {
  margin-right: 65px;
}
.margin-top-65 {
  margin-top: 65px;
}
.margin-bottom-65 {
  margin-bottom: 65px;
}
.margin-65 {
  margin: 65px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-60 {
  margin: 60px;
}
.margin-left-55 {
  margin-left: 55px;
}
.margin-right-55 {
  margin-right: 55px;
}
.margin-top-55 {
  margin-top: 55px;
}
.margin-bottom-55 {
  margin-bottom: 55px;
}
.margin-55 {
  margin: 55px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-50 {
  margin: 50px;
}
.margin-left-45 {
  margin-left: 45px;
}
.margin-right-45 {
  margin-right: 45px;
}
.margin-top-45 {
  margin-top: 45px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-45 {
  margin: 45px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-40 {
  margin: 40px;
}
.margin-left-35 {
  margin-left: 35px;
}
.margin-right-35 {
  margin-right: 35px;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-35 {
  margin: 35px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-30 {
  margin: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-right-25 {
  margin-right: 25px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-25 {
  margin: 25px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-20 {
  margin: 20px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-15 {
  margin: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-10 {
  margin: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-5 {
  margin: 5px;
}
.margin-left-0 {
  margin-left: 0px;
}
.margin-right-0 {
  margin-right: 0px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.margin-0 {
  margin: 0px;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.btn-rounded {
  border-radius: 30px !important;
  padding: 0  !important;
  font-weight: 600  !important;
  border: 2px solid #888;
  width: 30px;
  height: 30px;
  color: #fff  !important;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none  !important;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
}
.btn-rounded span {
  display: none;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 20px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
  border-radius: 20px;
}
.btn-rounded.active {
  background-color: #00aeef;
  color: white;
}
.background-login-register {
  position: fixed;
  background: #333;
  background-size: auto auto;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.header-container {
  position: absolute;
  width: 100%;
  top: 15px;
}
.header-container .logo-container {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-login {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.content-register {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#playground-container {
  height: 500px;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}
.main {
  margin: 50px 15px;
}
h1.title {
  font-size: 50px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
}
hr {
  width: 10%;
  color: #fff;
}
.form-group {
  margin-bottom: 15px;
}
label {
  margin-bottom: 15px;
}
input,
input::-webkit-input-placeholder {
  font-size: 11px;
  padding-top: 3px;
}
.main-login {
  background-color: #fff;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.form-control {
  height: auto !important;
  padding: 8px 12px !important;
}
.input-group {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.21) !important;
}
#button {
  border: 1px solid #ccc;
  margin-top: 28px;
  padding: 6px 12px;
  color: #666;
  text-shadow: 0 1px #fff;
  cursor: pointer;
  border-radius: 3px 3px;
  box-shadow: 0 1px #fff inset, 0 1px #ddd;
  background: #f5f5f5;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  background: linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#eeeeee', GradientType=0);
}
.main-center {
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 40px;
  background: #047fca;
  color: #FFF;
  text-shadow: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
}
span.input-group-addon i {
  color: #009edf;
  font-size: 17px;
}
.login-button {
  font-size: 14px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-button {
  font-size: 14px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-alone-button {
  font-size: 18px;
  text-align: center;
  margin: auto;
  width: 120px;
  position: relative;
}
a.disabled {
  pointer-events: none !important;
  cursor: default !important;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif !important;
}
.background-body-index {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  background-size: 100% 100%;
  height: 100%;
  z-index: -100;
}
.background-body-article {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  height: 100%;
  z-index: -100;
}
h3.title-header-content {
  font-family: 'Open Sans', sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-weight: 300;
  padding-top: 18px;
}
.line-header {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background: url("/images/assets/line-bottom-now-playing-orange.png");
  width: 79px;
  height: 4px;
}
.header-mobile-container {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  background: #000;
}
.header-mobile-container .logo-container {
  position: relative;
  top: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header-mobile-container .navbar .header-article {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  background: #047fca;
  text-align: left;
  z-index: 200;
}
.header-mobile-container .navbar .header-article .header-article-detail {
  color: #fff;
  position: relative;
  vertical-align: middle;
  bottom: 4px;
  font-size: 20px;
}
.header-mobile-container .navbar .header-article .header-article-share {
  display: block;
  color: white;
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
}
.header-mobile-container .navbar .header-article .btn-share {
  background: none;
  border: none;
  border-radius: 0;
  padding: 8px 10px;
  outline: none;
  font-size: 18pt;
  font-weight: 100;
  display: block;
}
.header-mobile-container .navbar .header-article .btn-back-to-menu {
  background: none;
  border: none;
  border-radius: 0;
  padding: 8px 10px;
  display: inline-block;
  outline: none;
  color: white;
  font-size: 18pt;
  text-align: center;
}
.header-mobile-container .navbar .header-article .btn-share:hover {
  color: #fc9230;
}
.header-mobile-container .navbar .header-article .btn-back-to-menu:hover {
  color: #fc9230;
}
.header-mobile-container .btn-header-container {
  background: rgba(0, 174, 239, 0.5);
  border: none;
  border-radius: 0;
  margin: 0;
  margin-bottom: 3px;
  display: inline-block;
  outline: 0;
  color: #fff;
  margin: 5px 0;
  height: 40px;
  line-height: 27px;
}
.header-mobile-container .btn-header-container:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.hidden-header {
  height: 50px;
  background: red;
  width: 100%;
  position: fixed;
  z-index: 1;
}
.mobile-navbar-container {
  background: #e6e6e6;
  height: 64px;
}
.navbar-image {
  float: left;
  width: 36%;
  padding: 5px;
}
.navbar-button {
  float: right;
  width: 58px;
  height: 54px;
  padding: 10px;
}
.navbar-button button {
  background-color: #ff7b00;
  color: #fff;
  border: none;
}
.toggle-button {
  background-color: #ff7b00;
  color: #fff;
  border: none;
}
.tab-option {
  position: absolute;
  background: white;
  top: 0;
  z-index: 102;
  width: 100%;
}
.tab-option .active {
  color: white!important;
  background: #fc9230 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.tab-option .tab-button {
  display: block;
  width: 50%;
  float: left;
  border-radius: 0;
  color: black;
  border: none;
  outline: none;
  background: white;
  font-size: 20px;
}
.tab-option .tab-button:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.error {
  margin-top: 180px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 25pt;
  position: absolute;
}
.list-schedule {
  color: #333;
  padding: 5px 15px;
  margin: 5px 0;
}
.list-schedule .today {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-family: sans-serif;
}
.list-schedule .schedule-mobile-now-playing {
  margin: 5px;
}
.list-schedule .title-movie {
  font-weight: 700;
  width: 100%;
  border-bottom: 1px solid #6c757d;
}
.list-schedule .divdate {
  padding-top: 10px;
}
.list-schedule .divshowtime {
  padding-top: 10px;
}
.list-schedule .date {
  height: 72px;
  line-height: 72px;
  text-align: center;
}
.list-schedule .studio-text {
  font-size: 16px;
  margin-bottom: -28px;
  top: 42px;
}
.list-schedule .studio {
  font-size: 72px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.list-schedule .ul-showtime {
  position: unset;
  overflow: hidden;
  margin-bottom: 0;
  padding-left: 0;
}
.list-schedule .ul-showtime .li-showtime {
  padding: 1px 1px;
  width: 33%;
  display: block;
  float: left;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}
.list-schedule .ul-showtime .li-showtime a {
  padding: 4px 10px;
  font-size: 16px;
  color: #212529;
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  width: 100%;
  float: left;
  border-radius: 0;
  font-weight: bold;
}
.list-schedule .ul-showtime .li-showtime a:hover {
  background-color: #ff7b00;
  color: white;
  border: 1px solid #333333;
}
.view-more {
  width: 97%;
  margin: 5px;
}
.view-more:hover {
  background-color: #ff7b00;
  border: #ff7b00;
}
.view-more:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 142, 0, 0.5);
}
.content-about {
  color: #000000;
  background: white;
  padding: 20px 0;
}
.content-about .container .row .box-shadow {
  color: black;
  box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.4), 0 12px 40px 0 rgba(255, 255, 255, 0.55);
  background: white;
  margin: 3px;
  height: 200px;
}
.content-about .container .row .box-shadow h4 {
  font-size: 1.2em;
  font-weight: bolder;
  margin: 5px;
  text-align: center;
  padding: 7px;
}
.content-about .container .row .box-shadow > p {
  margin: 1px;
  font-size: 9pt;
  padding: 5px;
  text-align: justify;
}
.content-movie-menu {
  z-index: 102;
  width: 100%;
  position: relative;
}
.news-container {
  background: #333;
  padding: 20px 0 0 0;
  overflow: hidden;
  height: 310px;
}
.content-mobile-now-playing {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: #222;
  z-index: 101;
  position: relative;
  top: 38px;
  left: 0;
  right: 0;
  padding-bottom: 50px;
  margin-bottom: 50px;
  padding-top: 28px;
}
.content-mobile-now-playing h3 {
  color: white;
}
.content-mobile-now-playing .container .row {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-now-playing .container .row .items {
  height: 240px;
  width: 48%;
  float: left;
  margin: 1%;
  position: relative;
}
.content-mobile-now-playing .container .row .brick-image {
  position: absolute;
  background-size: 100% 100%;
  height: 240px;
  width: 100%;
}
.content-mobile-now-playing .container .row .brick-info {
  position: absolute;
  height: 240px;
  width: 100%;
  top: 0;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.content-mobile-now-playing .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-now-playing .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin: 5px;
  vertical-align: middle;
  font-size: 10pt;
}
.content-mobile-now-playing .container .row .brick-info-order {
  background: url(/images/assets/nowplaying.png);
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}
.content-mobile-coming-soon {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  /* For browsers that do not support gradients */
  background-color: #222;
  z-index: 101;
  position: relative;
  top: 38px;
  left: 0;
  right: 0;
  padding-top: 28px;
  margin-bottom: 50px;
}
.content-mobile-coming-soon h3 {
  color: white;
}
.content-mobile-coming-soon .container .row {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-coming-soon .container .row .items {
  height: 240px;
  width: 48%;
  float: left;
  margin: 1%;
  position: relative;
}
.content-mobile-coming-soon .container .row .brick-image {
  position: absolute;
  background-size: 100% 100%;
  height: 240px;
  width: 100%;
}
.content-mobile-coming-soon .container .row .brick-info {
  position: absolute;
  height: 240px;
  width: 100%;
  top: 0;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.content-mobile-coming-soon .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-coming-soon .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: middle;
  font-size: 10pt;
}
.content-mobile-coming-soon .container .row .brick-info-order {
  background: url(/images/assets/upcoming.png);
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}
.content-mobile-box-office {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  z-index: 101;
  left: 0;
  right: 0;
  height: 755px;
  position: relative;
}
.content-mobile-box-office h3 {
  color: white;
}
.content-mobile-box-office .row {
  width: 100%;
}
.content-mobile-box-office .container .movimax-sarinah {
  display: block;
  top: 0;
  width: 100%;
}
.content-mobile-box-office .container .movimax-dinoyo {
  display: block;
  top: 350px;
  position: absolute;
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container .cinema-content {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  margin-top: 10px;
  color: white;
}
.content-mobile-box-office .container .cinema .cinema-container table.cinema-table {
  width: 100%;
}
.content-mobile-box-office .container .cinema .cinema-container table.cinema-table > tbody > tr > td {
  font-size: 12px;
  color: white;
}
.content-mobile-box-office .container .col-xs-12 .cinema img {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 250px;
}
.content-mobile-box-office .container .movimax-sarinah img {
  margin-top: 14px;
}
.content-mobile-box-office .container .col-xs-12 .cinema .maps {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-features {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: white;
  z-index: 101;
  top: 170px;
  left: 0;
  right: 0;
  min-height: 520px;
  padding-bottom: 50px;
}
.content-mobile-features h3 {
  color: black;
}
.content-mobile-features .container .row {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.content-mobile-features .container .row .box-shadow {
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 375px) {
  .content-mobile-features .container .row .box-shadow .logo-features-dlp {
    background-size: 100% 100%;
    position: relative;
    width: 100px;
    height: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 376px) {
  .content-mobile-features .container .row .box-shadow .logo-features-dlp {
    margin-top: 10px;
    background-size: 100% 100%;
    position: relative;
    width: 110px;
    height: 55px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .content-mobile-features .container .row .box-shadow .logo-features-barco {
    margin-top: 10px;
    background-size: 100% 100%;
    position: relative;
    width: 110px;
    height: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 376px) {
  .content-mobile-features .container .row .box-shadow .logo-features-barco {
    margin-top: 30px;
    background-size: 100% 100%;
    position: relative;
    width: 120px;
    height: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.content-mobile-features .container .row .box-shadow .logo-features-dolby {
  margin-top: 5px;
  background-size: 100% 100%;
  position: relative;
  width: 110px;
  height: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-mobile-features .container .row .box-shadow h4 {
  font-size: 1.2em;
  font-weight: bolder;
  margin: 5px;
}
.content-mobile-features .container .row .box-shadow > p {
  margin: 1px;
  font-size: 9pt;
}
.logo-features-dlp {
  margin: 0 auto;
  width: 200px;
  display: block;
}
.logo-features {
  margin: 20px auto 0 auto;
  width: 200px;
  display: block;
}
.price-container-parent {
  background: #333;
  color: #fff;
  padding: 20px;
}
.price-container-parent .price-title {
  font-weight: bold;
}
.price-container-parent .price-container {
  color: white;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
}
.col-xs-12 .contact-container {
  background-color: #333;
  padding: 20px;
}
.col-xs-12 .contact-container .contact-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.col-xs-12 .contact-container span {
  display: block;
  color: white;
  font-size: 15px;
}
.content-mobile-info {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 243px;
}
.content-mobile-info h3 {
  color: white;
}
.content-mobile-info .row {
  width: 100%;
}
.content-mobile-info .container .row .col-xs-12 .content-mobile-info-table {
  width: 100%;
  color: white;
  text-align: center;
  font-size: 10pt;
}
@media screen and (min-width: 321px) {
  .content-mobile-info {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    background: black;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 376px) {
  .content-mobile-info {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    background: black;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 426px) {
  .content-mobile-info {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    background: black;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    height: 520px;
  }
}
.content-mobile-footer-index {
  font-family: 'Open Sans', sans-serif;
  background: #111;
  position: relative;
  height: 50px;
  color: white;
}
.content-mobile-footer-index h4 {
  text-align: center;
  position: absolute;
  width: 100%;
  color: #282828;
}
.content-mobile-footer-article {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  z-index: 101;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: black;
  color: white;
  padding-top: 4px;
  position: relative;
}
.content-mobile-footer-article h4 {
  text-align: center;
  position: absolute;
  width: 100%;
  color: #fff;
  top: 30%;
}
.content-mobile-article {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: none;
  z-index: 101;
  left: 0;
  right: 0;
  color: #000;
  background-color: white;
}
.article-title {
  font-size: 20px;
}
.content-mobile-article .container .row {
  width: 100%;
}
.content-mobile-article .container .row .col-xs-5 {
  height: 194px;
}
.mobile-share-button {
  margin-top: 4px;
}
.mobile-share-button a {
  font-size: 20px;
}
.content-mobile-article .row .col-xs-5 .article-img-background {
  position: relative;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  width: 135px;
  height: 192px;
}
.content-mobile-article .row .col-xs-7.article-header-background {
  position: absolute;
  right: 15px;
  background: 0 0;
  width: 45%;
  height: 220px;
  padding: 0;
  text-align: center;
}
.header-mobile-container .navbar .header-article .header-article-facebook {
  display: block;
  color: white;
  position: absolute;
  vertical-align: middle;
  width: auto;
  top: 8px;
  right: 40px;
}
.header-mobile-container .navbar .header-article .header-article-facebook .img-facebook {
  width: 35px;
  border: 0;
  display: inline;
}
.header-mobile-container .navbar .header-article .header-article-twitter {
  display: block;
  color: white;
  position: absolute;
  vertical-align: middle;
  width: auto;
  top: 8px;
  right: 87px;
}
.header-mobile-container .navbar .header-article .header-article-twitter .img-twitter {
  width: 35px;
  border: 0;
  display: inline;
}
.content-mobile-article .container .row .article-header-background h5 {
  color: white;
  padding: 0;
  margin: 0;
  font-weight: bolder;
  font-size: 10pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header {
  margin-top: 30px;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-trailer {
  background: #ca0404;
  border: none;
  border-radius: 0;
  margin: 10px 0;
  display: inline-block;
  outline: 0;
  color: #fff;
  font-size: 8pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-ticket {
  background: #047fca;
  border: none;
  border-radius: 0;
  margin: 0;
  margin-bottom: 3px;
  display: inline-block;
  outline: none;
  color: white;
  font-size: 8pt;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-trailer:hover {
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.content-mobile-article .container .row .article-header-background .button-article-header .btn-article-ticket:hover {
  background: #fc9230;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.content-mobile-article table.table-article {
  width: 100%;
  background: none;
  margin-left: 0;
  margin-bottom: 10px;
}
.content-mobile-article table.table-article > tbody > tr > td,
th {
  border-bottom: 1px solid #aaa;
  padding: 10px 15px;
  font-size: 12px;
  position: relative;
  background: none;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td,
th {
  border-top: 1px solid #aaa;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  top: -8px;
  right: 0;
}
.content-mobile-article table.table-article > tbody > tr:first-child > td:last-child:before {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
.content-mobile-article table.table-article > tbody > tr > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
ul.cast-catalog {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  display: block;
}
ul.cast-catalog li {
  display: block;
  overflow: hidden;
  margin: 10px auto;
  width: 100%;
  border-bottom: 1px solid #888;
  padding-bottom: 5px;
}
ul.cast-catalog li .artist-link img {
  width: 100px;
  float: left;
  margin-right: 10px;
}
ul.cast-catalog li .artist-link .artist-bio {
  text-align: left;
  font-size: 11px!important;
}
.content-mobile-article-playlist {
  width: 100%;
  background: none;
  z-index: 101;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  color: white;
}
.content-mobile-article-playlist h3 {
  color: white;
}
.content-mobile-article-playlist .row {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.content-mobile-article-playlist .container .row .items {
  height: 240px;
  width: 48%;
  float: left;
  margin: 1%;
  position: relative;
}
.content-mobile-article-playlist .container .row .brick-image {
  background-size: 100% 100%;
  height: 225px;
  width: 100%;
}
.content-mobile-article-playlist .container .row .brick-info {
  position: absolute;
  height: 225px;
  width: 100%;
  -moz-transition-duration: .5s;
  transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  top: 0;
  left: 0;
}
.content-mobile-article-playlist .container .row .brick-info .brick-info-detail {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: auto;
}
.content-mobile-article-playlist .container .row .brick-info .brick-info-detail h5 {
  font-family: 'Open Sans', sans-serif;
  margin: 6px;
  vertical-align: middle;
  font-size: 10px;
}
.content-mobile-article-playlist .container .row .brick-info-order {
  background: url(/images/assets/nowplaying.png);
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}
.select-schedule-container {
  color: white;
  padding: 0 8px;
}
.select-schedule-container .select-theme {
  background: #282828;
  border: 1px solid #555;
  font-size: 15px;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-appearance: none;
}
.select-schedule-container .select-theme .option-theme {
  padding: 5px 10px;
  line-height: 22px;
  text-align: center;
}
.select-schedule-container .select-theme .option-theme:before {
  content: "";
  background-image: url(/images/assets/logo-small.png);
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  margin-right: 10px;
  word-wrap: break-word;
  margin-top: -4px;
}
.loader-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.row .col-lg-12 .notice-idn {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
}
.row .col-lg-12 .notice-eng {
  font-size: 13px;
  font-style: italic;
  text-align: center;
}
.content-schedule-container {
  background: #333;
  margin: 15px 17px;
}
.content-schedule-container .img-container {
  height: 180px;
  width: 130px;
  display: none;
}
.content-schedule-container .img-container img {
  width: 100%;
  height: 100%;
}
.content-schedule-container .content-container {
  height: 100%;
  color: #fff;
  margin-left: 12px;
  padding: 15px;
}
.content-schedule-container .content-container .title {
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #00aeef;
  padding-bottom: 5px;
}
.content-schedule-container .content-container .info-container {
  margin-top: 10px;
  position: relative;
}
.content-schedule-container .content-container .info-container .studio-container {
  float: right;
  display: block;
  width: 120px;
  height: 85px;
  position: relative;
  color: #fff;
}
.content-schedule-container .content-container .info-container .studio-container .label-studio {
  position: absolute;
  top: 0;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container .number {
  position: absolute;
  bottom: 12px;
  font-size: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: arial;
  font-weight: 700;
  text-decoration: none;
}
.content-schedule-container .content-container .info-container .studio-container .type {
  position: absolute;
  bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container:hover {
  background: #00aeef;
  color: #fff !important;
}
.content-schedule-container .content-container .info-container .info {
  display: inline;
  padding-right: 10px;
}
.content-schedule-container .content-container .info-container .font-size-15 {
  font-size: 14px;
}
.content-schedule-container .content-container .info-container .btn-schedule {
  border-radius: 0;
  background: #282828;
  border: 1px solid #333;
  font-family: Arial, Helvetica, sans-serif;
}
.description .content {
  font-size: 14px;
  color: white;
  padding: 15px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  background: #003f56;
}
.description .content.danger {
  background: #560000;
}
.lounge-title {
  position: relative;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
}
.lounge-title h4 {
  color: #ff7b00;
  font-weight: bold;
}
.lounge-title:before {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 88px;
  left: 0;
  top: -moz-calc(49%);
  top: calc(49%);
}
.lounge-title:after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #ff7b00;
  width: 88px;
  right: 0;
  top: -moz-calc(49%);
  top: calc(49%);
}
.lounge-about-us .col-md-4 {
  text-align: center;
}
.lounge-about-us .col-md-4 .view-lounge {
  text-align: center;
}
.lounge-about-us .col-md-4 .view-lounge .view-lounge-img {
  width: 140px;
}
.lounge-about-us .col-md-4 .view-lounge-text {
  font-size: 13px;
}
.facilities-foto {
  background: #333;
  color: white;
}
.facilities-foto .food-pict {
  text-align: left;
}
.facilities-foto .food-desc {
  padding: 25px;
  text-align: center;
}
.facilities-foto .food-desc .menu-title {
  font-weight: bolder;
  text-transform: uppercase;
}
.facilities-foto .food-desc .menu-decs {
  font-size: 13px;
}
.lounge-foto-sec-case {
  display: inline-block;
  -webkit-flex-wrap: inherit;
      -ms-flex-wrap: inherit;
          flex-wrap: inherit;
}
.lounge-foto-sec-case .lounge-foto-sec {
  padding: 0;
  display: inline-block;
}
.lounge-foto-sec-case .lounge-foto-sec a img {
  padding: 5px;
}
.menu-list-lounge .menu-lounge-title {
  margin-top: 10px;
  margin-bottom: 0;
  color: #ff7b00;
  text-transform: uppercase;
}
.menu-list-lounge .menu-lounge-title h5 {
  font-weight: bold;
}
.menu-list-lounge .menu-lounge-desc span {
  color: gray;
  font-size: 13px;
}
.circle {
  width: 60px;
  height: 60px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
  top: 10px;
}
.circle .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 60px;
  text-align: center;
  width: 60px;
  height: 60px;
  font-size: 17pt;
  margin-top: 0 !important;
}
.circle .circle-label {
  position: absolute;
  bottom: -15px;
  text-transform: uppercase;
  text-align: center;
  font-size: 8px;
  display: block;
  width: 100%;
}
.circle.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle.green-circle {
  background-image: url('/images/assets/green.png');
}
.btn-rounded {
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  margin-right: 2px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
  width: 15px;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 15px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
}
.selector-denpasar {
  margin: 0;
}
.selector-denpasar .col-md-4 {
  padding-left: 0px;
  padding-right: 0px;
}
.selector-denpasar .col-md-4 .control select #form-control {
  border: 1px solid #ff7b00;
  border-radius: 0;
}
.control-label {
  display: none;
}
.schedule-panel {
  width: 100%;
  padding: 15px 0;
  padding-bottom: 0;
  float: left;
}
.schedule-panel .main-content {
  overflow: hidden;
  border-bottom: 2px solid #ff7b00;
  position: relative;
  max-height: 380px;
}
.schedule-panel .main-content .film-cover {
  width: 37%;
  float: left;
  height: 171px;
  background-position: center;
  background-size: cover;
  position: relative;
}
.schedule-panel .main-content .film-cover .icon-watch-trailer {
  position: absolute;
  top: 25%;
  left: 8%;
  display: none;
}
.schedule-panel .main-content .schedule-content {
  width: 57%;
  float: left;
}
.schedule-panel .main-content .schedule-content .schedule-header {
  padding: 10px;
  padding-top: 0px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title a {
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  text-transform: uppercase;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-very-long a {
  font-size: 14px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-title-very-long.film-description {
  margin-top: 7px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-duration {
  font-size: 12px;
  color: #ff7b00;
  font-weight: 400;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description {
  width: 200px;
  font-size: 10px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table {
  width: 100%;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 10px;
  color: #000000;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding {
  width: 204px;
  font-size: 10px;
  padding-top: 27px;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table {
  width: 100%;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 10px;
  color: #000000;
}
.schedule-panel .main-content .schedule-content .schedule-header .film-description-padding table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-footer {
  padding: 0px!important;
  margin-left: -125px;
  margin-right: -16px;
}
.schedule-panel .main-content .schedule-content .schedule-footer .studio-label {
  margin-top: -14px;
  display: inline-block;
}
.schedule-panel .main-content .schedule-content .schedule-footer .studio-label a {
  color: #ff7b00!important;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.schedule-panel .main-content .schedule-content .schedule-footer .showtime-list {
  margin-top: 10px;
  margin-left: 0px;
}
.schedule-panel .main-content .schedule-content .schedule-footer .showtime-list .ul-showtime {
  overflow: auto;
  padding-left: 0;
}
.ul-showtime {
  margin: 0;
}
.ul-showtime .li-showtime {
  padding: 3px 3px;
  width: 25%;
  display: block;
  float: left;
}
.ul-showtime .li-showtime .showtime-denpasar {
  padding: 7px 13px;
  font-size: 15px;
  color: #212529;
  background-color: #e1e1e1;
  border: 1px solid #aaa;
  width: 100%;
  text-align: center;
  float: left;
  font-weight: bold;
}
.ul-showtime .li-showtime .showtime-denpasar:hover {
  background-color: #ff7b00;
  color: white;
  border: 1px solid #ff7b00;
  text-decoration: none;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.bx-controls-direction {
  display: none;
}
.bx-controls-auto {
  display: none;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: center;
  width: 100%;
}
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #ff7b00;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #ff7b00;
}
body {
  width: 100%;
  height: 100%;
  background: white;
  color: white;
}
.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
  overflow: hidden;
}
.slideout-menu-left {
  left: 0;
  color: #333;
  background-color: #e6e6e6;
  overflow: scroll;
}
.slideout-menu-right {
  right: 0;
}
.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF;
  /* A background-color is required */
  min-height: 100vh;
}
.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}
.slideout-open .slideout-menu {
  display: block;
}
.header-slideout-container {
  position: relative;
  height: 50px;
}
.header-slideout-text {
  height: 50px;
  width: 100%;
}
.header-slideout-text span {
  font-size: 20px;
  float: left;
  padding: 12px 10px 10px 15px;
  height: 50px;
}
.header-slideout-text button {
  color: #fff;
  background-color: #ff7b00;
  border: none;
  float: right;
  margin: 9px 10px;
}
.nav-container {
  background-color: #444;
  height: 40px;
  width: 100%;
  display: table;
  border-bottom: 0.4px solid #222;
}
.nav-logo {
  display: table-cell;
  width: 20%;
  text-align: center;
  vertical-align: middle;
}
.nav-logo i {
  margin-left: 3px;
  font-size: 20px;
  color: #ffffff;
}
.nav-text {
  display: table-cell;
  width: 80%;
  vertical-align: middle;
  font-size: 11px;
}
.nav-text a {
  color: white;
  text-align: left;
  padding: 10px 0px 10px 0;
}
.nav-text a:link {
  text-decoration: none;
}
.copyright-container {
  bottom: 0;
  margin-top: 15px;
  padding: 10px;
  font-size: 9px;
}
.copyright-container h5 {
  margin-bottom: 5px;
}
.copyright-container h6 {
  margin-bottom: 5px;
  margin-top: 8px;
}
.mobile-error {
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
  margin: 0;
}
.mobile-error .row {
  display: block;
}
.mobile-error .title-error {
  font-size: 50px;
  font-weight: 700;
}
.mobile-error .image-error {
  width: 80%;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
}
.mobile-error .page-not-found {
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
}
.mobile-error .btn-primary {
  margin-top: 5px;
}
.mobile-error .footer-error {
  margin-top: 30px;
  padding-bottom: 10px;
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
}
.mobile-error .footer-error .image-info {
  width: 40%;
  display: block;
  margin: 10px auto;
}
.mobile-error .footer-error .image-aurora {
  width: 200px;
  margin: 20px 0px;
}
.mobile-error .footer-error .info {
  font-size: 13px;
  margin: 0;
  text-align: center;
}
.mobile-confirm {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
  background: #eeeeee;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eeeeee 0%, #eeeeee 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#eeeeee', GradientType=0);
  /* IE6-9 */
  padding: 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.mobile-confirm h3 {
  font-weight: bold;
}
.mobile-confirm .form-control {
  font-size: 50px;
  width: 80%;
  margin: 10px;
}
.mobile-confirm .btn-success {
  margin: 10px;
}
.mobile-confirm a.btn.btn-default {
  margin: 10px;
}
.mobile-confirm .verification {
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}
.news {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 265px;
  position: relative;
}
.news .swiper-container {
  width: 100%;
  height: 290px;
  margin-left: auto;
  margin-right: auto;
}
.news .swiper-slide {
  padding: 0 45px;
  display: block;
}
.news .swiper-slide .swiper-img {
  width: 100%;
  display: block;
}
.news .swiper-slide .swiper-content .title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
.news .swiper-slide .swiper-content .info {
  font-size: 11px;
  color: #ddd;
}
.news .right {
  position: absolute;
  top: 40%;
  right: 10px;
  z-index: 10;
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  width: 30px;
  padding: 6px;
}
.news .left {
  position: absolute;
  top: 40%;
  left: 10px;
  z-index: 10;
  border: 2px solid #888;
  border-radius: 15px;
  height: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 0;
  width: 30px;
  padding: 6px;
}
.news .swiper-pagination {
  bottom: 5px;
}
.container {
  color: black;
}
.container .title {
  position: absolute;
  left: 10%;
  right: 10%;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  max-width: 80%;
  top: 36%;
  text-shadow: 1px 1px #333;
}
.container .title .subtitle {
  font-size: 12px;
  font-weight: normal;
}
.container .share-button {
  width: 35px;
  margin: 0 5px;
}
.container .image-article-potrait {
  position: relative;
  width: 100%;
  max-height: 210px;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}
.container .content {
  margin-top: 20px;
}
.container .content .content-title {
  font-weight: bold;
}
.container .content .text {
  font-size: 14px;
  line-height: 19px;
}
.container .content img {
  max-width: 100%;
}
.container .more {
  text-align: center;
  font-weight: bold;
}
.container .line-header {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background: url(/images/assets/line-bottom-now-playing-orange.png);
  width: 79px;
  height: 4px;
}
.container .other-news {
  width: 45%;
  margin: 5px 6px;
  float: left;
  overflow: hidden;
  height: 184px;
}
.container .other-news .other-image {
  width: 100%;
  margin-bottom: 10px;
}
.container .other-news .other-title {
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.content-news-list-mobile {
  color: #000;
  text-decoration: none;
}
.content-news-list-mobile .headline-wrapper {
  max-width: 100%;
  margin-left: -130px;
}
.content-news-list-mobile .headline-wrapper .bx-viewport {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 235px;
  margin-left: 130px;
}
.content-news-list-mobile ul.headline-slider-mobile {
  list-style: none;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.content-news-list-mobile ul.headline-slider-mobile li {
  float: left;
  list-style: none;
  position: relative;
  width: 330px;
}
.content-news-list-mobile ul.headline-slider-mobile li .image-header {
  position: relative;
  margin: 0;
  width: 100%;
  height: 235px;
  background-size: 100%;
  overflow: hidden;
}
.content-news-list-mobile ul.headline-slider-mobile li .image-header img {
  width: 100%;
  height: 165px;
}
.content-news-list-mobile ul.headline-slider-mobile li .image-header .title-header {
  position: absolute;
  top: 107px;
  font-size: 12px;
  font-weight: bold;
  background: rgba(9, 9, 9, 0.82);
  width: 100%;
  height: 58px;
  overflow: hidden;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}
.content-news-list-mobile ul.headline-slider-mobile li .image-header .title-header .subtitle-header {
  font-size: 10px;
  text-transform: none;
  font-weight: normal;
}
.content-news-list-mobile ul.headline-slider-mobile li .content-header {
  margin-top: 5px;
  font-size: 11px;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
}
.content-news-list-mobile .content {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 15px;
  color: #000;
}
.content-news-list-mobile .content img {
  width: 50%;
}
.content-news-list-mobile .content .title-content {
  position: absolute;
  top: 1%;
  left: 52%;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  width: 50%;
  height: 15px;
  overflow: hidden;
}
.content-news-list-mobile .content .article {
  position: absolute;
  top: 20%;
  left: 52%;
  font-size: 10px;
  height: 65px;
}
.content-news-list-mobile .content .read-more {
  position: absolute;
  bottom: 5px;
  left: 52%;
  background: #337ab7;
  color: #fff;
  border-radius: 2px;
  font-weight: bold;
  border: 1px solid #337ab7;
  padding: 3px;
  text-decoration: none;
}
.content-news-list-mobile .highlight-topic {
  font-weight: 700;
  background: #0e8fe6;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  margin-top: 10px;
  width: 100px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.content-news-list-mobile .line {
  width: 100%;
  border-bottom: 3px solid #0e8fe6;
}
.content-news-list-mobile .copyright-container-mobile {
  margin-top: 15px;
  padding: 10px;
  font-size: 9px;
  background: #000;
  color: #fff;
  margin-bottom: 10px;
}
.header-studio {
  margin-top: 10px;
  font-size: 15px;
  font-family: roboto, sans-serif;
  text-transform: uppercase;
}
.header-studio .type-studio {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.header-studio .place-studio {
  font-size: 18px;
  font-weight: bold;
}
.studio-number {
  /* margin-top: 10px; */
  border: 1px solid #000;
  text-align: center;
  width: 72px;
  margin: 5px 0 0 70px;
  font-family: roboto, sans-serif;
}
.studio-number .number {
  font-weight: bold;
  font-size: 40px;
}
.studio-number .studio {
  text-transform: uppercase;
  font-weight: bold;
}
.line {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
.content-info .title-info {
  font-size: 16px;
  font-weight: bold;
  text-transform: initial;
  margin-left: 10px;
}
.content-info .image-info {
  padding: 0px 10px;
  margin-bottom: 5px;
  width: 100%;
}
.content-info .info {
  padding: 0px 10px;
  font-size: 12px;
  margin-bottom: 25px;
}
.title-gallery {
  font-size: 23px;
  font-weight: bolder;
}
.line-gallery {
  display: block;
  margin: 10px auto;
  height: 5px;
}
.image-round {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.image-round .pict-gallery {
  border-radius: 5px;
  max-width: 160px;
  max-height: 109px;
  width: 160px;
  min-height: 109px;
  margin: 5px;
}
.in-studio {
  list-style: none;
  padding: 10px;
}
.in-studio li {
  margin: 10px 0px;
}
.in-studio li img {
  width: 100%;
}
.help-mobile {
  background: white;
  padding-bottom: 50px;
}
.help-mobile .input-group {
  margin-top: 10px;
  border-radius: 5px;
}
.help-mobile .input-group button {
  padding: 8px 12px;
  background: transparent;
}
.help-mobile .help-container [aria-expanded="false"] .plus-icon {
  display: block;
}
.help-mobile .help-container [aria-expanded="false"] .minus-icon {
  display: none;
}
.help-mobile .help-container [aria-expanded="true"] .plus-icon {
  display: none;
}
.help-mobile .help-container [aria-expanded="true"] .minus-icon {
  display: block;
}
.help-mobile .help-container .col-md-12 {
  margin: 1px;
}
.help-mobile .help-container .btn-rounded {
  display: inline-block;
}
.help-mobile .help-container .question-container {
  cursor: pointer;
  border-radius: 3px;
  margin: 1px 0;
  padding: 5px;
}
.help-mobile .help-container .question-container i {
  position: absolute;
  top: 13px;
  left: 18px;
  color: #ff7b00;
  font-size: 15px;
}
.help-mobile .help-container .question-container .question-container[aria-expanded] {
  height: 240px;
}
.help-mobile .help-container .question-container .question {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  min-height: 20px;
  font-weight: bold;
  margin-left: 20px;
  border-bottom: 1px solid #ccc;
}
.help-mobile .help-container .well {
  background: white;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 4px;
  margin: 0;
  font-size: 12px;
}
.content-result-page-mobile {
  position: relative;
}
.text-not-found {
  position: absolute;
  color: #000;
  top: 100px;
  font-size: 16px;
  left: 30px;
  text-align: center;
  right: 30px;
}
.header-result-page {
  color: white;
  background-color: #313131;
  margin-bottom: 20px;
  padding: 20px;
}
.result-list {
  margin-bottom: 30px;
}
.result-list .col-md-12 {
  text-align: center;
  padding: 133px 0;
}
.result-list .col-md-12 span {
  display: block;
}
.result-list .col-md-12 .eng {
  font-style: italic;
}
.result-page-title {
  font-weight: 700;
  font-size: 17px;
}
.content-result-page {
  width: 100%;
}
.result-detail {
  margin-top: -202px;
  margin-left: 135px;
  position: relative;
}
.result-detail .table-result-first {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
}
.result-detail a {
  color: black;
}
.result-detail a:hover {
  color: #ff7b00;
  -moz-transition: 0.3;
  transition: 0.3;
}
.result-detail .result-movie-title {
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 13px;
  max-height: 35px;
  font-size: 15px;
  overflow: hidden;
  color: #313131;
}
.result-detail .mv-tag {
  text-align: center;
  width: 100px;
  line-height: 30px;
  font-weight: 700;
  background-color: #ff7b00;
  color: #fff;
}
.result-detail .mv-time {
  padding-left: 10px;
  font-size: 13px;
  padding-top: 1px;
  color: #313131;
}
.result-detail ul {
  margin-top: 25px;
}
.result-detail ul li label {
  color: #ff7b00;
}
.result-detail ul li span {
  color: #000000;
}
.result-detail p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: normal;
}
.result-cover-movie {
  width: 37%;
  height: 190px;
}
.bg-grey {
  background-color: #313131;
}
.option-search {
  margin: 40px 0;
}
.title-with-line {
  text-align: center;
  margin-bottom: 25px;
  font-size: 20px;
}
.result-cover-movie {
  position: relative;
}
.icon-now-playing {
  position: absolute;
  top: 0;
}
.icon-upcoming {
  position: absolute;
  top: 0;
}
.icon-upcoming .upcoming {
  width: 110px;
}
.result-list .film-description {
  width: 200px;
  font-size: 10px;
}
.result-list .film-description table {
  width: 100%;
}
.result-list .film-description table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 10px;
  color: #000000;
}
.result-list .film-description table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.result-list .film-description-padding {
  width: 100%;
  font-size: 10px;
  padding-top: 27px;
}
.result-list .film-description-padding table {
  width: 100%;
}
.result-list .film-description-padding table tr > td {
  padding: 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid gray;
  font-size: 10px;
  color: #000000;
}
.result-list .film-description-padding table tr > td.emphasis {
  font-weight: bold;
  color: #ff7b00;
}
.schedule-panel .main-content .schedule-content .schedule-footer {
  padding: 20px 5px;
}
.align-center {
  text-align: center;
}
.bx-pager-item {
  display: none;
}
